import { createRouter, createWebHistory } from "vue-router";

import Index from "../views/index.vue";

const routes = [
  // ========== Welcome page: index.vue ==========
  {
    path: "/",
    name: "Index",
    component: Index,
    meta: { title: "Home" },
  },
  // ========== About Wiichihew Page ==========
  {
    path: "/about-wiichihew",
    name: "AboutWiichihew",
    component: () => import("../views/about-wiichihew.vue"),
    meta: { title: "About Wiichihew" },
  },
  // ========== Disclaimer Page ==========
  {
    path: "/disclaimer",
    name: "Disclaimer",
    component: () => import("../views/disclaimer.vue"),
    meta: { title: "Disclaimer" },
  },
  // ========== Find Someone to talk to Page ==========
  {
    path: "/find-someone-to-talk-to",
    name: "FindSomeoneToTalkTo",
    component: () => import("../views/find-someone-to-talk-to.vue"),
    meta: { title: "Find Someone to talk to" },
  },

  // ========== Get in Touch With us Page ==========
  {
    path: "/get-in-touch-with-us",
    name: "GetInTouchWithUs",
    component: () => import("../views/get-in-touch-with-us.vue"),
    meta: { title: "Get in Touch With Us" },
  },
  // ========== Get Help For MySelf pages ==========
  {
    path: "/get-help-for-myself",
    name: "GetHelpForMySelf",
    component: () => import("../views/get-help-for-myself/index.vue"),
    meta: { title: "Get Help For Myself" },
  },
  {
    path: "/get-help-for-myself/intent",
    name: "Intent",
    component: () => import("../views/get-help-for-myself/intent.vue"),
    meta: { title: "Intent" },
  },
  {
    path: "/get-help-for-myself/thoughts",
    name: "Thoughts",
    component: () => import("../views/get-help-for-myself/thoughts.vue"),
    meta: { title: "Thoughts" },
  },
  {
    path: "/get-help-for-myself/planning-suicide",
    name: "PlanningSuicide",
    component: () =>
      import("../views/get-help-for-myself/planning-suicide.vue"),
    meta: { title: "Planning Suicide" },
  },
  // ========== Get Help For Someone Else pages ==========
  {
    path: "/get-help-for-someone-else",
    name: "GetHelpForSomeoneElse",
    component: () => import("../views/get-help-for-someone-else/index.vue"),
    meta: { title: "Get Help For Someone Else" },
  },
  {
    path: "/get-help-for-someone-else/help-for-someone-else",
    name: "HelpForSomeoneElse",
    component: () =>
      import("../views/get-help-for-someone-else/help-for-someone-else.vue"),
    meta: { title: "Help For Someone Else" },
  },
  {
    path: "/get-help-for-someone-else/additional-coping-strategies",
    name: "AdditionalCopingStrategies",
    component: () =>
      import(
        "../views/get-help-for-someone-else/additional-coping-strategies.vue"
      ),
    meta: { title: "Additional Coping Strategies" },
  },
  // ========== Tools and Resources Pages ==========
  {
    path: "/tools-and-resources",
    name: "ToolsAndResources",
    component: () => import("../views/tools-and-resources/index.vue"),
    meta: { title: "Tools and Resources" },
  },
  {
    path: "/tools-and-resources/call-a-crisis-hotline",
    name: "CallACrisisHotline",
    component: () =>
      import("../views/tools-and-resources/call-a-crisis-hotline.vue"),
    meta: { title: "Call a Crisis Hotline" },
  },
  {
    path: "/tools-and-resources/challenge-your-thoughts",
    name: "ChallengeYourThoughts",
    component: () =>
      import("../views/tools-and-resources/challenge-your-thoughts.vue"),
    meta: { title: "Challenge Your Thoughts" },
  },
  {
    path: "/tools-and-resources/create-a-safety-plan",
    name: "CreateASafetyPlan",
    component: () =>
      import("../views/tools-and-resources/create-a-safety-plan.vue"),
    meta: { title: "Create a Safety Plan" },
  },
  {
    path: "/tools-and-resources/explore-cultural-healing-practices",
    name: "ExploreCulturalHealingPractices",
    component: () =>
      import(
        "../views/tools-and-resources/explore-cultural-healing-practices.vue"
      ),
    meta: { title: "Explore Cultural Healing Practices" },
  },
  {
    path: "/tools-and-resources/find-a-counselor",
    name: "FindACounselor",
    component: () =>
      import("../views/tools-and-resources/find-a-counselor.vue"),
    meta: { title: "Find a Counselor" },
  },
  {
    path: "/tools-and-resources/find-a-support-group",
    name: "FindASupportGroup",
    component: () =>
      import("../views/tools-and-resources/find-a-support-group.vue"),
    meta: { title: "Find a Support Group" },
  },
  {
    path: "/tools-and-resources/learn-how-to-ask-for-help",
    name: "LearnHowToAskForHelp",
    component: () =>
      import("../views/tools-and-resources/learn-how-to-ask-for-help.vue"),
    meta: { title: "Learn How to Ask for Help" },
  },
  {
    path: "/tools-and-resources/make-a-hope-box",
    name: "MakeAHopeBox",
    component: () => import("../views/tools-and-resources/make-a-hope-box.vue"),
    meta: { title: "Make a Hope Box" },
  },
  {
    path: "/tools-and-resources/name-your-emotions",
    name: "NameYourEmotions",
    component: () =>
      import("../views/tools-and-resources/name-your-emotions.vue"),
    meta: { title: "Name Your Emotions" },
  },
  {
    path: "/tools-and-resources/practice-breathing-exercises",
    name: "PracticeBreathingExercises",
    component: () =>
      import("../views/tools-and-resources/practice-breathing-exercises.vue"),
    meta: { title: "Practice Breathing Exercises" },
  },
  {
    path: "/tools-and-resources/practice-grounding-techniques",
    name: "PracticeGroundingTechniques",
    component: () =>
      import("../views/tools-and-resources/practice-grounding-techniques.vue"),
    meta: { title: "Practice Grounding Techniques" },
  },
  {
    path: "/tools-and-resources/practice-guided-meditation",
    name: "PracticeGuidedMeditation",
    component: () =>
      import("../views/tools-and-resources/practice-guided-meditation.vue"),
    meta: { title: "Practice Guided Meditation" },
  },
  {
    path: "/tools-and-resources/watch-videos-and-podcasts",
    name: "WatchVideosAndPodcasts",
    component: () =>
      import("../views/tools-and-resources/watch-videos-and-podcasts.vue"),
    meta: { title: "Watch Videos and Podcasts" },
  },
  // ========== About Suicide Pages ==========
  {
    path: "/about-suicide",
    name: "AboutSuicide",
    component: () => import("../views/about-suicide/index.vue"),
    meta: { title: "About Suicide" },
  },
  {
    path: "/about-suicide/suicide-FAQ",
    name: "suicide-FAQ",
    component: () => import("../views/about-suicide/suicide-FAQ.vue"),
    meta: { title: "Suicide FAQ" },
  },
  {
    path: "/about-suicide/suicide-myths",
    name: "SuicideMyths",
    component: () => import("../views/about-suicide/suicide-myths.vue"),
    meta: { title: "Suicide Myths" },
  },

  {
    path: "/about-suicide/suicide-risk-factors",
    name: "SuicideRiskFactors",
    component: () => import("../views/about-suicide/suicide-risk-factors.vue"),
    meta: { title: "Suicide Risk Factors" },
  },
  {
    path: "/about-suicide/suicide-warning-signs",
    name: "SuicideWarningSigns",
    component: () => import("../views/about-suicide/suicide-warning-signs.vue"),
    meta: { title: "Suicide Warning Signs" },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Page404",
    component: () => import("../views/page-404.vue"),
    meta: { title: "Page 404" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "active",
  routes,

  scrollBehavior() {
    return { top: 0 };
  },
});

// Source: https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});
export default router;
