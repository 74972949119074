import { createApp } from "vue";
import App from "./App.vue";
// PWA
import "./registerServiceWorker";

//  Router
import router from "./router";
//  Axios
import axios from "axios";
import VueAxios from "vue-axios";
//gtag
import { createGtm } from "@gtm-support/vue-gtm";
//  Stylesheets
import "./assets/tailwind.css";
import "@splidejs/splide/dist/css/splide.min.css";

const app = createApp(App);

app.use(router);
// Source: https://www.npmjs.com/package/@gtm-support/vue-gtm
app.use(
  createGtm({
    id: "GTM-MC4BQTB",
  })
);

app.use(VueAxios, axios);

app.mount("#app");
