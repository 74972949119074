<template>
  <!-- Quote -->
  <section class="my-8 quote-sect md:my-12 md:py-8">
    <div class="max-w-4xl px-4 mx-auto sm:px-6">
      <div class="rounded-xl quote-text">
        <p>“<slot name="text"></slot>”</p>
        <p class="text-right">- <slot name="sign">PD</slot></p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Quote",
  props: {
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
/* QUOTE */
.quote-text {
  background: #f0f2f8;
  padding: 40px 100px;
  font-style: italic;
  position: relative;
  z-index: 1;
}
.quote-text:after,
.quote-text:before {
  content: "";
  position: absolute;
  top: 38px;
  left: 15px;
  right: 0;
  bottom: 0;
  background-image: url(../assets/images/quote.png);
  background-repeat: no-repeat;
  background-size: 4rem;
  z-index: -1;
  display: block;
}
.quote-text:before {
  top: 0;
  bottom: 15px;
  right: 15px;
  transform: rotate(180deg);
}
@media (max-width: 767px) {
  .quote-text {
    padding: 25px;
    font-size: 16px;
    line-height: 30px;
  }
  .quote-text:after {
    top: 15px;
  }
}
</style>
