<template>
  <div>
    <!-- Welcome Banner -->
    <section class="mt-10 md:mt-12 banner-sect">
      <div class="max-w-5xl px-4 mx-auto sm:px-6">
        <div class="text-center banner-text">
          <div
            class="mb-3 text-5xl font-bold lg:text-5xl md:text-4xl text-blue"
          >
            Welcome to Wiichihew
          </div>
          <div
            class="mb-4 text-2xl font-bold text-blue-700 lg:text-2xl md:mb-8"
          >
            Support for Indigenous youth experiencing suicide
          </div>

          <div class="text-4xl font-bold lg:text-4xl md:text-3xl text-blue">
            What kind of support do you need right now?
          </div>

          <div class="justify-center md:flex align-center gap-x-6">
            <router-link
              @click="
                this.$gtm.trackEvent({
                  event: 'gtm.btnClick',
                  category: 'button',
                  action: 'Clicked on',
                  label: 'I need help For Myself',
                  btnTitle: 'GetHelpForMySelf-btn',
                })
              "
              :to="{ name: 'GetHelpForMySelf' }"
              class="my-8 btn btn-green btn-icon"
            >
              <img src="../assets/images/menu-icons/menu1.svg" alt="" />
              <span class="mx-0.5">I need help for myself</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10.922"
                height="28"
                viewBox="0 0 10.922 28"
                class="inline-block ml-1.5 opacity-50"
              >
                <path
                  id="Path_4072"
                  data-name="Path 4072"
                  d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
                  transform="translate(-831.098 -480.029)"
                  fill="currentColor"
                />
              </svg>
            </router-link>
            <router-link
              @click="
                this.$gtm.trackEvent({
                  event: 'gtm.btnClick',
                  category: 'button',
                  btnTitle: 'GetHelpForSomeoneElse-btn',
                  label: 'I need help For SomeoneElse',
                  action: 'Clicked on',
                })
              "
              :to="{ name: 'GetHelpForSomeoneElse' }"
              class="my-8 btn btn-green btn-icon"
            >
              <img src="../assets/images/menu-icons/menu2.svg" alt="" />
              <span class="mx-0.5">I need help for someone else</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10.922"
                height="28"
                viewBox="0 0 10.922 28"
                class="inline-block ml-1.5 opacity-50"
              >
                <path
                  id="Path_4072"
                  data-name="Path 4072"
                  d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
                  transform="translate(-831.098 -480.029)"
                  fill="currentColor"
                />
              </svg>
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <EmergencySupport />

    <!-- QUICK ACCESS -->
    <section class="quick-sect">
      <div class="max-w-6xl px-4 mx-auto sm:px-6">
        <div class="text-center">
          <div class="mb-8 text-2xl font-bold text-blue">
            Quickly access tools and resources to help keep you safe
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 gap-y-8 gap-x-6">
            <router-link
              @click="
                this.$gtm.trackEvent({
                  event: 'gtm.btnClick',
                  category: 'button',
                  btnTitle: 'PracticeGuidedMeditation-btn',
                  label: 'Practice Guided Meditation',
                  action: 'Clicked on',
                })
              "
              :to="{ name: 'PracticeGuidedMeditation' }"
            >
              <QuickAccess
                arrow="text-green"
                text="Practice guided meditation"
                img="meditation.svg"
              />
            </router-link>
            <router-link
              @click="
                this.$gtm.trackEvent({
                  event: 'gtm.btnClick',
                  category: 'button',
                  btnTitle: 'PracticeBreathingExercises-btn',
                  label: 'Practice Breathing Exercises',
                  action: 'Clicked on',
                })
              "
              :to="{ name: 'PracticeBreathingExercises' }"
            >
              <QuickAccess
                arrow="text-red"
                text="Practice breathing exercises"
                img="exercises.svg"
              />
            </router-link>
            <router-link
              @click="
                this.$gtm.trackEvent({
                  event: 'gtm.btnClick',
                  category: 'button',
                  label: 'Create A safety Plan',
                  btnTitle: 'CreateASafetyPlan-btn',
                  action: 'Clicked on',
                })
              "
              :to="{ name: 'CreateASafetyPlan' }"
            >
              <QuickAccess
                arrow="text-white-100"
                text="Create a safety plan"
                img="create-a-safety-plan.svg"
              />
            </router-link>
            <router-link
              @click="
                this.$gtm.trackEvent({
                  event: 'gtm.btnClick',
                  category: 'button',
                  btnTitle: 'LearnHowToAskForHelp-btn',
                  label: 'Learn How To Ask For Help ',
                  action: 'Clicked on',
                })
              "
              :to="{ name: 'LearnHowToAskForHelp' }"
            >
              <QuickAccess
                arrow="text-white-100"
                text="Learn how to ask for help"
                img="learn-how-to-ask-for-help.svg"
              />
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <FooterCard
      img="want-to-talk.png"
      text="Find someone to talk to"
      name="FindSomeoneToTalkTo"
    />
    <Quote>
      <template v-slot:text
        >Talking to someone helped me, I was feeling hopeless, alone and
        abandoned. I was experiencing suicidal thoughts often, but then I talked
        to someone, and I got the support I needed</template
      >
      <template v-slot:sign>PD </template>
    </Quote>
  </div>
</template>

<script>
import EmergencySupport from "@/components/EmergencySupport.vue";
import FooterCard from "@/components/FooterCard.vue";
import Quote from "@/components/Quote.vue";
import QuickAccess from "@/components/QuickAccess.vue";
export default {
  name: "Index",
  components: {
    EmergencySupport,
    FooterCard,
    Quote,
    QuickAccess,
  },
};
</script>

<style scope>
/* BANNER */
.banner-sect {
  position: relative;
  z-index: 1;
}
.banner-sect:before {
  content: "";
  background-image: url(../assets/images/home-bg.jpg);
  background-size: cover;
  background-position: center;
  position: absolute;
  top: -35vh;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

/* BANNER buttons */
.btn-icon {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px;
  padding-right: 50px;
  font-size: 20px;
  position: relative;
  justify-content: flex-start;
  text-align: left;
  line-height: normal;
}
.btn-icon img {
  height: 60px;
  margin-right: 20px;
}
.btn-icon svg {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
