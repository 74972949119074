<template>
  <!-- Emergency Support -->
  <section class="support-sect">
    <div class="max-w-5xl px-8 mx-auto sm:px-6">
      <div class="text-center support-text">
        <div class="mb-6 text-2xl font-bold text-red">
          <slot name="title"> Get Emergency Support </slot>
        </div>
        <p>
          <slot name="content">
            If you have acted on suicidal thoughts and tried to hurt yourself
            today, <br class="hidden md:inline-block" /><span
              class="font-bold text-red"
              >call 911</span
            >
            or
            <span class="font-bold text-red"
              >go to the nearest Emergency Room</span
            >
            (or ask someone to take you there).
          </slot>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "EmergencySupport",
};
</script>

<style scoped>
/* EMERGENCY SUPPORT */
.support-sect {
  padding: 100px 0;
  background-size: cover;
  background-image: url(../assets/images/support-bg.png);
  background-position: center;
  background-repeat: no-repeat;
}
.support-text {
  background: #eaeaea;
  border: 7px solid #b92031;
  border-radius: 10px;
  padding: 20px 3px;
  line-height: 1.5rem;
  position: relative;
}
@media screen and (min-width: 768px) {
  .support-sect {
    background-size: contain;
  }
}
</style>
