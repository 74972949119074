<template>
  <!-- HEADER -->
  <div>
    <!--  Mobile menu, show/hide based on mobile menu state. -->
    <div
      class="absolute right-0 z-50 w-full h-screen overflow-auto sm:p-8 p-2.5 transition ease-in transform"
      id="menu"
      ref="menu"
    >
      <div class="pb-6 sm:px-5 sm:pt-5">
        <div class="flex items-center justify-between">
          <div>
            <img class="w-auto logo" src="../assets/images/logo-white.svg" />
          </div>
          <div class="-mr-2">
            <button
              type="button"
              class="inline-flex items-center justify-center p-2 text-white rounded-md bg-white/0"
              id="menu-toggle-close"
              ref="menu_toggle_close"
              @click="menuClose"
            >
              <span class="sr-only">Close menu</span>

              <svg
                class="w-10 h-10 ml-8"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>

        <div class="flex justify-center">
          <GreenBtn
            text="Find someone to talk to"
            name="FindSomeoneToTalkTo"
            @click="menuClose"
          >
            ></GreenBtn
          >
        </div>
        <div class="px-5 mt-12 md:pl-10">
          <nav class="grid">
            <router-link
              :to="{ name: 'GetHelpForMySelf' }"
              class="flex items-center py-4 opacity-50 hover:opacity-100"
              @click="menuClose"
              class-active="active"
            >
              <img
                src="../assets/images/menu-icons/menu1.svg"
                class="menu-icon"
                height="50"
                alt=""
              />
              <span class="ml-3">Get help for myself</span>
            </router-link>
            <router-link
              :to="{ name: 'GetHelpForSomeoneElse' }"
              class="flex items-center py-4 opacity-50 hover:opacity-100"
              @click="menuClose"
              class-active="active"
            >
              <img
                src="../assets/images/menu-icons/menu2.svg"
                class="menu-icon"
                height="50"
                alt=""
              />
              <span class="ml-3">Get help for someone else </span>
            </router-link>
            <router-link
              :to="{ name: 'ToolsAndResources' }"
              class="flex items-center py-4 opacity-50 hover:opacity-100"
              @click="menuClose"
              class-active="active"
            >
              <img
                src="../assets/images/menu-icons/menu3.svg"
                class="menu-icon"
                height="50"
                alt=""
              />
              <span class="ml-3">Tools & resources </span>
            </router-link>
            <router-link
              :to="{ name: 'AboutWiichihew' }"
              class="flex items-center py-4 opacity-50 hover:opacity-100"
              @click="menuClose"
              class-active="active"
            >
              <img
                src="../assets/images/menu-icons/menu4.svg"
                class="menu-icon"
                height="50"
                alt=""
              />
              <span class="ml-3">About Wiichihew </span>
            </router-link>
            <router-link
              :to="{ name: 'AboutSuicide' }"
              class="flex items-center py-4 opacity-50 hover:opacity-100"
              @click="menuClose"
              class-active="active"
            >
              <img
                src="../assets/images/menu-icons/menu4.svg"
                class="menu-icon"
                height="50"
                alt=""
              />
              <span class="ml-3">About Suicide </span>
            </router-link>
            <router-link
              :to="{ name: 'GetInTouchWithUs' }"
              class="flex items-center py-4 opacity-50 hover:opacity-100"
              @click="menuClose"
              class-active="active"
            >
              <img
                src="../assets/images/menu-icons/menu5.svg"
                class="menu-icon"
                height="50"
                alt=""
              />
              <span class="ml-3">Get in touch with us </span>
            </router-link>
          </nav>
        </div>
      </div>
    </div>

    <div class="relative z-10">
      <div class="px-4 mx-auto max-w-7xl sm:px-6">
        <div
          class="flex flex-wrap items-center justify-between py-6 md:space-x-10"
        >
          <div class="flex justify-start lg:w-0 lg:flex-1">
            <router-link to="/">
              <img
                class="w-auto logo md:h-20"
                src="../assets/images/logo.svg"
                alt=""
                height="70"
              />
            </router-link>
          </div>

          <!-- Hamburger menu  -->
          <div class="lg:hidden sm:order-last">
            <button
              type="button"
              class="inline-flex items-center justify-center p-2 bg-white rounded-md text-blue"
              aria-expanded="false"
              id="menu-toggle"
              @click="menuOpen"
            >
              <svg
                class="block w-10 h-10"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          <!-- End of hamburger  menu  -->
          <div class="mx-auto">
            <GreenBtn
              name="FindSomeoneToTalkTo"
              text="Find someone to talk to"
            ></GreenBtn>
          </div>
        </div>
        <nav
          class="items-center justify-center hidden space-x-5 text-center lg:flex menu-bar"
        >
          <router-link class-active="active" :to="{ name: 'GetHelpForMySelf' }"
            >Get help for myself</router-link
          >
          <router-link :to="{ name: 'GetHelpForSomeoneElse' }"
            >Get help for someone else</router-link
          >
          <router-link :to="{ name: 'ToolsAndResources' }"
            >Tools & resources</router-link
          >
          <router-link :to="{ name: 'AboutWiichihew' }"
            >About Wiichihew</router-link
          >
          <router-link :to="{ name: 'AboutSuicide' }"
            >About suicide</router-link
          >
          <router-link :to="{ name: 'GetInTouchWithUs' }"
            >Get in touch with us</router-link
          >
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import GreenBtn from "./GreenBtn.vue";
export default {
  name: "Header",
  components: { GreenBtn },
  methods: {
    menuOpen() {
      this.$refs.menu.classList.add("show");
      document.documentElement.style.overflow = "hidden";
    },
    menuClose() {
      this.$refs.menu.classList.remove("show");
      document.documentElement.style.overflow = "auto";
    },
  },
};
</script>
<style scoped>
/* Active link  */
.active {
  opacity: 100 !important;
  font-weight: 700;
}

/* MENU------------------- */
#menu {
  background: #3c5eaa;
  transform: scale(0);
  transition: transform 0.2s;
  transform-origin: 90% 50px;
}
#menu.show {
  background: #3c5eaa;
  transform: scale(1);
  transition: transform 0.2s;
}
.menu-bar a {
  color: #3c5eaa;
  font-size: 16px;
}
.menu-bar a:after {
  content: "";
  background: #22b46e;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 5px;
  transition: width 0.4s;
}
.menu-bar a.active {
  font-weight: bold;
  color: #22b46e;
}
.menu-bar a:hover {
  color: #22b46e;
}
.menu-bar a:hover:after,
.menu-bar a.active:after {
  width: 100%;
  transition: width 0.4s;
}

@media (max-width: 767px) {
  .header-btn {
    flex: 0 0 100%;
  }
  .logo {
    height: 60px;
  }
}
/* MEDIA CSS --------------------- */
@media (max-width: 1024px) {
  #menu {
    transform: scale(0);
    z-index: 99;
  }
  #menu:after {
    content: "";
    background-image: url(../assets/images/menu-icons/bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
  #menu nav a {
    color: #fff;
    font-size: 20px;
    border-bottom: 1px solid #5a77b7;
  }
  #menu nav a img {
    height: 70px;
  }
}
</style>
