<template>
  <Header />
  <!-- Start of views -->
  <router-view></router-view>
  <!-- End of View -->
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped>
/* Styles are only visible on this component */
</style>
