<template>
  <a
    class="btn btn-icon-left bg-white-200 text-blue hover:bg-blue hover:text-white"
  >
    <div class="ic">
      <img :src="require(`@/assets/images/quick-access/${img}`)" alt="" />
    </div>
    <span class="w-full">{{ text }}</span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.922"
      height="28"
      viewBox="0 0 10.922 28"
      :class="arrow"
    >
      <path
        id="Path_4072"
        data-name="Path 4072"
        d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
        transform="translate(-831.098 -480.029)"
        fill="currentColor"
      />
    </svg>
  </a>
</template>

<script>
export default {
  name: "QuickAccess",
  props: {
    text: {
      type: String,
      default: "text",
    },
    img: {
      type: String,
      default: "",
    },
    arrow: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
/* QUICK ACCESS */
.btn-icon-left {
  display: flex;
  align-items: center;
  background: #f8f8f8;
  border-width: 5px;
  color: #3c5eaa;
  font-weight: normal;
  font-size: 18px;
  line-height: normal;
  position: relative;
  height: 100px;
  margin-left: 80px !important;
  padding-left: 60px;
  text-align: left;
}
.ic {
  width: 120px;
  height: 120px;
  border: 8px solid #3c5eaa;
  border-radius: 50%;
  position: absolute;
  left: -80px;
  top: -17px;
  bottom: 0;
  background: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ic img {
  height: 80px;
  object-fit: contain;
  margin: 0 auto;
}

.btn-icon-left:hover {
  background: #3c5eaa;
  color: #fff;
}
@media screen and (min-width: 560px) {
  .btn-icon-left {
    font-size: 20px;
    padding-left: 90px;
  }
}
</style>
