<template>
  <!-- TALK -->
  <section class="my-8 talk-sect md:my-12 md:py-8 xx">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="talk-text">
        <div class="talk-img">
          <img :src="require(`@/assets/images/${img}`)" alt="" />
        </div>
        <div class="text-center xx-2 md:ml-8 md:text-left">
          <div class="mb-4 text-2xl font-bold text-blue">
            <slot name="header"> Want to talk? </slot>
          </div>
          <p class="mb-5">
            <slot name="content">
              Sometimes talking it out helps. We’ve put together a list of
              trusted talk and text hotlines you can lean on now.
            </slot>
          </p>
          <span class="flex justify-center md:justify-start">
            <GreenBtn :text="text" :name="name"></GreenBtn>
            <hr class="mr-5" />
            <slot name="btn"> </slot>
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import GreenBtn from "./GreenBtn.vue";
export default {
  name: "FooterCard",
  components: {
    GreenBtn,
  },
  props: {
    text: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    img: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
/* TALK SECTION */
.talk-text {
  background: #f8f8f8;
  padding-top: 20px;
  padding-bottom: 40px;
  border-radius: 10px;
  position: relative;
  margin-left: 150px;
  padding-left: 120px;
}
.talk-img {
  position: absolute;
  top: 0;
  left: -150px;
  bottom: 0;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  overflow: hidden;
  background-image: url(../assets/images/mask.jpg);
  padding: 15px;
  background-size: contain;
  background-position: center;
}
.talk-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .xx {
    padding-top: 60px;
  }

  .talk-text {
    padding: 25px;
    margin: 0;
  }
  .talk-img {
    position: relative;
    left: 0;
    right: 0;
    top: -90px;
    margin: 0 auto;
  }
}
</style>
