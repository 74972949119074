<template>
  <!-- FOOTER -->
  <footer class="xs-footer-section text-gray">
    <div class="px-4 mx-auto max-w-7xl sm:px-6">
      <div class="flex flex-wrap justify-between md:text-left">
        <div class="w-full mb-10 md:w-1/4 md:mb-0">
          <router-link to="/">
            <img
              class="w-auto footer-logo"
              src="../assets/images/logo.svg"
              alt=""
            />
          </router-link>
        </div>

        <div class="flex-wrap w-full md:w-1/3 lg:w-2/5 md:flex">
          <ul class="list-none footer-list lg:w-3/5">
            <li>
              <router-link
                :to="{ name: 'GetHelpForMySelf' }"
                class="hover:text-green"
                >Get help for myself</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'GetHelpForSomeoneElse' }"
                class="hover:text-green"
                >Get help for someone else</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'ToolsAndResources' }"
                class="hover:text-green"
                >Tools & resources</router-link
              >
            </li>
          </ul>
          <ul class="list-none footer-list lg:w-2/5">
            <li>
              <router-link
                :to="{ name: 'AboutWiichihew' }"
                class="hover:text-green"
                >About Wiichihew</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'AboutSuicide' }"
                class="hover:text-green"
                >About suicide</router-link
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="flex flex-wrap justify-end md:text-left">
        <ul class="list-none md:w-1/3 lg:w-1/5 footer-list">
          <li>
            <router-link
              :to="{ name: 'FindSomeoneToTalkTo' }"
              class="font-bold text-green hover:text-gray"
            >
              Find someone to talk to
              <svg
                class="inline-block"
                xmlns="http://www.w3.org/2000/svg"
                width="10.922"
                height="28"
                viewBox="0 0 10.922 28"
              >
                <path
                  id="Path_4072"
                  data-name="Path 4072"
                  d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
                  transform="translate(-831.098 -480.029)"
                  fill="currentColor"
                ></path>
              </svg>
            </router-link>
          </li>
          <li class="pb-0">
            <router-link
              :to="{ name: 'Disclaimer' }"
              class="font-bold text-green hover:text-gray"
            >
              Disclaimer
              <svg
                class="inline-block"
                xmlns="http://www.w3.org/2000/svg"
                width="10.922"
                height="28"
                viewBox="0 0 10.922 28"
              >
                <path
                  id="Path_4072"
                  data-name="Path 4072"
                  d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
                  transform="translate(-831.098 -480.029)"
                  fill="currentColor"
                ></path>
              </svg>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </footer>
  <!-- bottom border -->
  <div class="flex items-center justify-between footer-border">
    <div class="bg-green"></div>
    <div class="bg-blue"></div>
    <div class="bg-yellow"></div>
    <div class="bg-red"></div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  components: {},
};
</script>

<style scoped>
footer {
  background-image: url("../assets/images/footer-bg.jpg");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0 40px;
}
.footer-list li {
  padding-bottom: 8px;
}

.footer-border > div {
  height: 5px;
  width: 100%;
}
</style>
