<template>
  <div class="mt-8 text-center md:flex md:mt-0">
    <router-link
      :to="{ name: name }"
      class="flex items-center justify-between btn btn-green"
      :class="{ 'w-full': widthFull }"
      id="btn-find"
    >
      <div>
        {{ text }}
      </div>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10.922"
          height="28"
          viewBox="0 0 10.922 28"
          class="inline-block ml-1.5 opacity-50"
        >
          <path
            id="Path_4072"
            data-name="Path 4072"
            d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
            transform="translate(-831.098 -480.029)"
            fill="currentColor"
          />
        </svg>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "GreenButton",
  props: {
    text: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    widthFull: {
      Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
